<template>
  <div class="sha wrapper">
    <iframe class="iframe" :src="iFrameLink"></iframe>
  </div>
</template>

<script>
export default {
  name: "GoogleSheetWrapper",
  props: {
    iFrameLink: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../common/breakpoints.scss";

.wrapper {
  height: 100%;

  @media #{$tablet} {
    padding-bottom: 30px;
  }

  .iframe {
    width: 99%;
    height: 100%;
  }
}
</style>
